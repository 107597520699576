import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

const About = () => (
  <Layout>
    <SEO title='About' />
    <h1>Hi from the About page</h1>
  </Layout>
)

export default About
